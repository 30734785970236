import needStatusesMap from '../Maps/needStatusesMap.js'

export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        create: false,
        conexe: false,
        cronology: false,
        approve: false,
        reject: false,
        edit: false,
        delete: false,
        deleteConexed: false,
        unpairSimple: false
      }
    }
  },
  created() {
    const statuses = Object.keys(needStatusesMap)
    const baseRule = 'needs'

    this.initPermision(`${baseRule}.conexe`,'conexe',statuses)
    this.initPermision(`${baseRule}.get`,'list',statuses)
    this.initPermision(`${baseRule}.view_history`,'cronology',statuses)
    this.initPermision(`${baseRule}.post`,'create',statuses)
    this.initPermision(`${baseRule}.patch`,'edit',statuses)
    this.initPermision(`${baseRule}.delete`,'delete',statuses)
    this.initPermision(`${baseRule}.deleteConexed`,'deleteConexed',statuses)
    this.initPermision(`${baseRule}.unpairSimple`,'unpairSimple',statuses)
    this.initPermision(`${baseRule}.approve`,'approve',statuses)
    this.initPermision(`${baseRule}.reject`,'reject',statuses)
  }
}