<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <span
        ><router-link :to="'/'" class="breadcrumb_home">Acasă / </router-link>
        {{ $route.name }} {{ breadcrumbs }}</span
      >
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div
        v-if="!avizoMode"
        class="col2_subHead"
        style="margin-top: 1.2rem"
        :style="!PERMISIONS.conexe ? 'justify-content:flex-end;' : ''"
      >
        <div
          v-if="PERMISIONS.conexe"
          class="card_Parent"
          v-click-outside="closeCart"
        >
          <span
            @click="cartVisible = !cartVisible"
            :class="{ active: cartVisible }"
            class="card_Btn"
          >
            <span class="card_Btn_count">
              <span>{{ cartData.length }}</span>
            </span>
            <i class="fas fa-shopping-cart"></i>
          </span>
          <transition name="card_List" :duration="{ enter: 500, leave: 0 }">
            <div
              v-show="cartVisible"
              class="card_List"
              :class="{ fixCardListTranslate: !PERMISIONS.create }"
            >
              <div class="card_List_inner">
                <ul class="card_List_Parent">
                  <transition name="card_List_empty">
                    <li v-show="cartData.length < 2" style="text-align: center">
                      <h4>Selectați minim 2 nevoi</h4>
                    </li>
                  </transition>
                  <template v-if="cartData.length > 1">
                    <li>
                      <span style="color: green">
                        <i class="fas fa-circle"></i>
                        Social
                      </span>
                      <span style="color: #8a6d3b">
                        <i class="fas fa-circle"></i>
                        Non-social
                      </span>
                    </li>
                    <li
                      v-for="item in cartData"
                      :key="item.id"
                      class="card_List_item"
                    >
                      <div class="card_List_item_controls">
                        <div
                          @click.stop="delCheckbox(item.id, item.index)"
                          class="card_List_item_controls_btn card_item_delete"
                          ref="button"
                          v-tooltip="'Elimină Nevoie'"
                        >
                          <i class="fas fa-minus-circle"></i>
                        </div>
                      </div>
                      <div class="card_List_item_icon">
                        <i
                          class="fas fa-receipt"
                          :style="
                            ({
                              true: 'color:green;',
                              false: 'color:#8a6d3b;',
                            }[item.social]) || ''
                          "
                          v-tooltip="
                            ({
                              true: 'Social',
                              false: 'Non-social',
                            }[item.social]) || ''
                          "
                        ></i>
                      </div>
                      <div class="card_List_item_info">
                        <div class="card_List_item_title">
                          {{ item.productName }}
                        </div>
                        <div class="card_List_item_details">
                          <span v-tooltip="`Pretul: ${item.estimatedPrice}`"
                            >Pretul: <b>{{ item.estimatedPrice }}</b></span
                          >
                          <span v-tooltip="`Cantitate: ${item.qty}`"
                            >Cantitate: <b>{{ item.qty }}</b></span
                          >
                        </div>
                      </div>
                    </li>
                  </template>
                </ul>
                <div
                  v-if="cartData.length > 1"
                  @click="createConexedNeed()"
                  class="card_List_Btn"
                  :class="{ cartTypeLoading: !postCartType }"
                  :style="getButtCartTypeInfo.style"
                >
                  <div>
                    <img src="../assets/media/propuneri.svg" alt="" />
                    <span>Creează nevoie {{ getButtCartTypeInfo.val }}</span>
                  </div>
                  <div>
                    <i class="fas fa-spinner fa-spin"></i>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div
          v-if="PERMISIONS.create"
          @click="addNeed"
          class="Button1 Btn_center"
          style="margin-left: 1.5rem"
        >
          <div class="icon">
            <img
              src="../assets/media/for_table/circle_ADD_new_etap.svg"
              alt=""
            />
          </div>
          <span class="Btn_content"> Adaugă </span>
        </div>
      </div>
    </div>

    <template v-if="PERMISIONS.list">
      <Table
        :key="tableReload"
        :Data="data"
        :viewRows="showActiveRows"
        :avizoMode="avizoMode"
        @previousPage="setPage"
        @nextPage="setPage"
        @changePage="changePage"
        @checkboxManager="checkboxManager"
        @Btn2="editNeed"
        @Btn3="delNeed"
        @Btn8="(id) => changeStatusNeed(id, 1)"
        @Btn9="
          (id, parentId) =>
            Number.isInteger(parentId)
              ? unpairSimple(id, parentId)
              : changeStatusNeed(id, 2)
        "
        @Btn11="viewRaport"
        @Btn13="openCronology"
        @Btn14="cancelNeedAvizo"
        @Btn20="generateRF"
        @Btn29="cancelNeed"
        @fullReload="reloadPage"
        @readFilteredData="filteredDataManager"
        @filterQuery="tableFilterManager"
        @subData="loadSubData"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/needsPermMixin.js";
import updateRoleStatus from '../mixins/updateUserRoleStatus.js'
import mainMixin from '../mixins/mainMixin.js'
import {
  NEEDS_PAGINATED,
  NEEDS_MODIFY,
  NEEDS_MODIFY_FORMDATA,
  NEED_CANCEL,
  NEEDS_CONEXE,
  NEEDS_FROM_CONEXED,
  NEEDS_AVIZO,
  NEEDS_AVIZO_MODIFY,
  NEEDS_CANCEL_AVIZO,
  NEEDS_AVIZO_TYPE,
  REFERENCE_ADD,
  FINANCING,
  USERS_INSTITUTION,
  NEEDS_REMOVE_CONNECTED,NEEDS_ONCE
} from "../api.js";
import { mapMutations, mapGetters } from "vuex";
import Table from "@/components/Table";
import DialogModal from "@/components/DialogModal";

const cartTypeApi = window["apiTimeout"](1000);

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus, mainMixin],
  inject: ["needs", "needsCartList", "NeedRejectData"],
  components: {
    Table,
  },
  data() {
    const x = localStorage.getItem("needsCart")
    
    return {
      tableReload: 1,
      cartVisible: false,
      cartEvents: ["button"],
      showActiveRows: [],
      breadcrumbs: "",
      dataLoading: false,
      openedCartType: false,
      crudLoading: false,
      isEditable: false,
      cartData:
        this.JSONvalide(x) && x[0] == "["
          ? JSON.parse(x)
          : [],
      data: {
        ready: 1,
        options: {
          searchModule: "NEEDS_PAGINATED",
          partialDisableFixedCols: window.innerWidth < 500 ? [2, 1] : false
        },
        head: [
          {
            title: "NR.",
            minimize: 2,
            sort: true,
            fixed: {
              left: true,
            },
          },
          {
            title: "Denumire instituție",
            show: false,
            meta: "instName",
            queryKey: "institution.name",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Denumire departament",
            show: false,
            meta: "depName",
            queryKey: "department.name",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Nume Prenume Solicitant / ID ConectX",
            show: false,
            meta: "applicant",
            queryKey: "createdBy:allUser",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Denumire propunere",
            sort: true,
            queryKey: "product.name",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Categorie achiziție",
            sort: true,
            queryKey: "product.cpvCategory.category",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Data și <br> ora creării",
            minWidth: "15rem",
            queryKey: "createdAt",
            bottomHead: {
              quikFiltre: "date",
              range: true,
            },
          },
          {
            title: "Codul CPV",
            queryKey: "product.cpvCategory.cpvCode",
            metaType: "CPV_LIST",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: 'Unitatea <div style="word-wrap:no-wrap;">de măsură</div>',
            sort: true,
            queryKey: "product.measurementUnit.shortName",
            metaType: "MU_LIST",
            bottomHead: {
              quikFiltre: true,
              multiselect: true,
            },
          },
          {
            title: "Cantitatea",
            sort: true,
            queryKey: "qty",
            bottomHead: {
              quikFiltre: "number",
              range: true,
            },
          },
          {
            title: "Valoarea estimată bucată <br> (fără TVA)",
            sort: true,
            queryKey: "estimatedPriceWoTva",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Cota Tva %",
            sort: true,
            queryKey: "cotaTva",
            bottomHead: {
              quikFiltre: "number",
              range: true,
            },
          },
          {
            title: "Valoarea estimată bucată <br> (cu TVA)",
            sort: true,
            queryKey: "estimatedPrice",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Valoarea estimată <br> totală",
            sort: true,
            queryKey: "sumNeed",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Caracter nevoie",
            sort: true,
            queryKey: "needIsSocial",
            bottomHead: {
              quikFiltre: "list",
              options: [
                { value: "1", label: "Social" },
                { value: "0", label: "Non-social" },
              ],
            },
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
                right: true
            },
            queryKey: "needReferateObj.needReferateStatus",
            bottomHead: {
              quikFiltre: "statusReference",
              tooltip: "Status referat",
            },
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
            queryKey: "needType",
            bottomHead: {
              quikFiltre: "type",
              tooltip: "Tip nevoie",
            },
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
            queryKey: "status",
            bottomHead: {
              quikFiltre: "status",
              tooltip: "Status",
            },
          },
        ],
        body: [],
        subTableData: {},
        institution: {},
      },
      subTableData: {},
      currentPage: this.getPaginatedParams(),
      totalItemsLength: 0,
      tableFilter: {
        query: "",
        baseQuery: "",
      },
      avizoNeeds: [],
      avizoModes: [],
    };
  },
  watch: {
    userRole(val) {
      this.updateRoleStatus(val);
    },
    cartVisible(x) {
      this.syncNeedsCartType(x);
    },
    needs: {
      handler() {
        this.clearCartNeeds();
        this.reloadPage();
      },
      deep: true,
    },
    NeedRejectData: {
      handler(val) {
        if (!val?.NeedRejectData) {
          this.$toastr.e("Încercați mai târziu.");
          return;
        }
        const x = val.NeedRejectData;
        if (x.cancelNeed) {
          this.cancelNeed(x.needIndex, undefined, x.rejectedComment ?? "");
          return;
        }
        if (x.cancelAvizo) {
          this.cancelNeedAvizo(
            x.cancelAvizo[0],
            x.cancelAvizo[1],
            x.rejectedComment || true
          );
          return;
        }
        this.changeStatusNeed(
          x.needIndex,
          x.approving ? 5 : x.approving === false ? 4 : x.rejecting ? 3 : 7,
          x.rejectedComment
        );
      },
      deep: true,
    },
    needsCartList: {
      handler() {
        this.clearCartNeeds();
      },
      deep: true,
    },
    tableReload() {
      this.tableFilter.query = "";
    },
    avizoMode() {
      this.initHeadTable();
      this.avizoManager();
      this.reloadPage();
    },
  },
  computed: {
    ...mapGetters(["userRole", "getNeedsTableData"]),
    avizoMode() {
      return this.$route?.path.includes('/nevoi/avizare-aprobare');
    },
    postCartType() {
      return this.isString(this.openedCartType);
    },
    getButtCartTypeInfo() {
      const x = this.openedCartType;
      const styleMap = {
        false: "background-color: black;",
        true: "background-color: gray;",
        2: "background-color: red;",
      };
      const nameMap = {
        complex: "complexă",
        similar: "similară",
      };

      return {
        style: styleMap[x] || "background-color: green;",
        val: this.isString(x) ? nameMap[x] || x : "",
      };
    },
  },
  methods: {
    ...mapMutations({ addWindow: "addWindow", saveData: "setNeedsTableData" }),
    avizoManager() {
      Object.keys(this.tableFilter).forEach((e) => (this.tableFilter[e] = ""));
    },
    syncNeedsCartType(x) {
      if (x === undefined) return;

      if (x && this.cartData.length > 1) {
        this.openedCartType = true;
        cartTypeApi.call(this, () =>
          NEEDS_AVIZO_TYPE(this.cartData.map((e) => e.id))
            .then((res) => {
              if (!this.openedCartType) return;

              if (this.isString(res?.data?.result)) {
                this.openedCartType = res.data.result;
              } else {
                this.openedCartType = 2;
                this.$toastr.e(
                  "Stabilirea tipului nevoii conexate ce urmează să fie creată a eșuat."
                );
              }
            })
            .catch((err) => {
              this.openedCartType = 2;
              this.$toastr.e(err);
            })
        );
      } else {
        this.openedCartType = false;
      }
    },
    generateRF(index, parentIndex) {
      const item = this.findItem(index, parentIndex);
      if (!item || !Number.isInteger(item.id)) return;
      const currentDate = new Date();
      const currentUTCYear = currentDate.getUTCFullYear();

      const minDatePickerDate = currentDate.setYear(currentUTCYear - 1);
      const maxDatePickerDate = new Date().setYear(currentUTCYear + 15);

      const financing = {
        label: "Modalitatea de finanțare",
        id: "financingMethod",
        type: "select",
        selectLabel: "sourceName",
        selectData: [],
        loading: true,
        errReason: "Selectați modalitatea de finanțare a referatului de necesitate.",
      }
      const errFinancing = msg => {
        this.$toastr.e(msg || 'Sursele de finanțare nu au fost încărcate.')
        this.$modal.hide('AddNeedReference')
      }

      const achizition = {
        label: "Responsabil achiziție",
        id: "achizitionRole",
        type: "select",
        selectData: [],
        loading: true,
        errReason: "Selectați responsabilul de achiziție al referatului de necesitate.",
      }
      const errAchizition = msg => {
        this.$toastr.e(msg || 'Responsabilii de achiziție nu au fost încărcați.')
        this.$modal.hide('AddNeedReference')
      }

      FINANCING.get(1, 500).then(res => {
        if(Array.isArray(res?.data?.result)) {
          financing.selectData = res.data.result
          financing.loading = false
        } else {
          errFinancing()
        }
      }).catch(errFinancing)

      USERS_INSTITUTION().then(res => {
        if(Array.isArray(res?.data?.result)) {
          achizition.selectData = res.data.result.flatMap(e => 
            e?.userRoles?.map(i => 
              i ?
                {
                  ...i,
                  id: i.id ?? this.makeid(6),
                  _userId: e.id,
                  label: this.readUserObject(e) + (i.department?.name ? ` — ${i.department?.name}` : '') + (i.role ? (` — ${this.changeRoleView(i.role)}`) : '')
                }
              : null
            ) || []
          )
          achizition.loading = false
        } else {
          errAchizition()
        }
      }).catch(errAchizition)

      this.$modal.show(
        DialogModal,
        {
          target: "AddNeedReference",
          title: "Generează Referat Necesitate",
          inputs: [
            {
              label: "Denumire referat",
              id: "name",
              errReason: "Introduceți denumirea referatului de necesitate.",
            },
            {
              label: "Anul de încadrare",
              id: "date",
              errReason:
                "Introduceți anul de încadrare al referatului de necesitate.",
              type: "date",
              dateType: "year",
              valueType: "YYYY",
              format: 'YYYY',
              valueDisable(year) {
                if (minDatePickerDate > year || maxDatePickerDate < year) {
                  return true;
                }

                return false;
              },
            },
            financing,
          ],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Adaugă",
            handler: data => {
              this.setSafeLoad(12000);
              const x = this.getCurrentTime();

              REFERENCE_ADD({
                need: item.id,
                institution: this.getUserInstitution(true)?.id,
                department: this.getUserDepartment(true)?.id,
                name: data.name,
                nryear: +data.date,
                sursaDeFinantareSiPozitiaBuget: String(data.financingMethod.id),
              })
                .then((res) => {
                  if (Array.isArray(res?.data?.result) && res.data.result[0]) {
                     this.goToNeedReference(res.data.result[0].id)
                     this.setLoad(false);
                     this.reloadPage();
                  } else {
                    this.setLoad(false);
                    this.$toastr.e("A apărut o eroare.");
                    this.reloadPage();
                  }
                })
                .catch((err) => {
                  this.setLoad(false);
                  this.$toastr.e(err);
                  this.reloadPage();
                });
            },
          },
        },
        {
          name: "AddNeedReference",
          adaptive: true,
          width: "650",
          height: "600",
        }
      );
    },
    goToNeedReference(id) {
      this.$modal.show(
        DialogModal,
        {
          target: "AccessNeedReference",
          title: "Doriți să fiți redirecţionat către pagina referatului",
          closeBtnText: "Nu",
          button: {
            type: 2,
            value: "Da",
            handler: () => {
              this.$router.push(`/${this.$route.params.navigation}/referate/${id}`);
            },
          },
        },
        {
          name: "AccessNeedReference",
          adaptive: true,
          width: "650",
          height: "300",
        }
      );
    },
    cancelNeedAvizo(index, parentIndex, confirmed) {
      const item = this.findItem(index, parentIndex);
      if (!item || !Number.isInteger(item.id)) return;
      if (!confirmed) {
        this.addWindow({
          title: "Oprire flux de avizare",
          subtitle: item.productName,
          rejectData: { cancelAvizo: [index, parentIndex] },
          labels: [
            "Flux oprit",
            "opririi fluxului",
            "Motivul opririi fluxului",
          ],
          button: { value: "Oprire flux", type: 3 },
          modal: 11,
        });
        return;
      }
      this.reloadPage(true);
      NEEDS_CANCEL_AVIZO(item.id, confirmed === true ? false : confirmed)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s("Fluxul de avizare a fost oprit.");
          } else {
            this.$toastr.e(
              "La anularea fluxului de avizare Au apărut errori."
            );
          }
          this.reloadPage();
        })
        .catch((err) => {
          this.reloadPage();
          this.$toastr.e(err);
        });
    },
    async unpairSimple(index, parentIndex) {
      const item = this.findItem(index, parentIndex);

      if (!item || !Number.isInteger(item.id)) return;
      

       NEEDS_REMOVE_CONNECTED(item.id)
        .then((res) => {
          if (
            res?.meta?.HttpStatusCode &&
            this.checkHttpStatusCode(res?.meta?.HttpStatusCode)
          ) {
            this.$toastr.s("Nevoia selectată nu mai este atașată.");
            this.reloadPage();
          } else {
            this.$toastr.e(res.data.response);
          }
        })
        .catch((err) => {
          this.$toastr.e(err);
        });
    },
    findItem(index, parentIndex) {
      let find;
      if (Number.isInteger(parentIndex)) {
        find = this.subTableData[parentIndex];
        if (find) find = find[index];
      } else {
        find = this.getNeedsTableData.find((e) => e.index === index);
      }
      return find || undefined;
    },
    async loadSubData(index, callback) {
      const findItem = this.getNeedsTableData.find((e) => e.index === index);
      let prepare = [];
      
      if (findItem && Number.isInteger(findItem.id)) {
        await NEEDS_FROM_CONEXED(findItem.id)
          .then((res) => {
            if (Array.isArray(res?.data?.result) && res.data.result.length) {
              const result = res.data.result.map(el => ({...el, needReferateObj: findItem.data.needReferateObj }))
              callback(
                this.prepareData(result, {
                  minimize: true,
                  noCheckbox: true,
                  isFromConexed: !findItem.data.needReferateObj
                })
              );
            } else {
              callback(Object.assign([], { isEmpty: true }));
            }
            
            this.subTableData[index] = res.data.result.map((e) =>
              Object.assign({}, { data: e, needReferateObj: findItem.data.needReferateObj , id: e.id })
            );

          })
          .catch((err) => {
            this.$toastr.e(err);
            callback(Object.assign(prepare, { isEmpty: true }));
          });
      } else {
        callback(Object.assign(prepare, { isEmpty: true }));
      }
    },
    tableFilterManager(newQuery) {
      if (this.isString(newQuery)) {
        this.tableFilter.query = newQuery;
      }
    },
    filteredDataManager(data, callback) {
      if (Number.isInteger(data?.data?.recordsQuantity)) {
        this.totalItemsLength = data.data.recordsQuantity;
      }
      if (this.avizoMode || Array.isArray(data?.data?.result)) {
        callback([
          this.prepareData(data.data.result, {
            minimize: true,
          }),
          data.data.recordsQuantity,
        ]);
        this.saveData(
          this.prepareData(data.data.result, {
            minimize: false,
          })
        );
        this.subTableData = {};
        this.data.options.totalItems = parseInt(data.data.recordsQuantity);
        this.currentPage = {
          from: 0,
          to: localStorage.getItem("tableShowPerPage")
            ? parseInt(localStorage.getItem("tableShowPerPage"))
            : 15,
        };
        this.dataLoading = false;
        this.readCheboxStorage();
      } else {
        callback([false]);
      }
    },
    initHeadTable() {
      const permMap = {
        instName: ["superadmin"],
        depName: [
          "superadmin",
          "responsabil_achizitii",
          "admin_institutie",
          "supervizor_achizitii",
          "sef_institutie",
        ],
        applicant: [
          "superadmin",
          "responsabil_achizitii",
          "supervisor",
          "functionar",
          "supervizor_achizitii",
          "admin_institutie",
          "sef_institutie",
        ],
      };
      const role = this.getUserRole();

      Object.entries(permMap).forEach((e) => {
        const findHead = this.data.head.find((head) => head.meta == e[0]);
        if (findHead && (this.avizoMode || e[1].includes(role))) {
          findHead.show = true;
        }
      });
    },
    checkTableHeader(meta) {
      const find = this.data.head.find((e) => e.meta == meta);
      if (find?.show !== false) {
        return true;
      }
      return false;
    },
    async reloadPage(doNotUpload) {
      this.saveData([]);
      this.data.body = [];
      this.tableReload = 1;
      this.totalItemsLength = 0;
      this.dataLoading = false;
      this.tableFilter.query = "";
      if (!doNotUpload) setTimeout(this.managePages, 1000, true)
    },
    viewRaport(id) {
      const data = this.getNeedsTableData;
      const findNeed = data.find((el) => el.NR == id);
      if (!findNeed) return;

      this.addWindow({
        title: "Raport nevoie",
        subtitle: findNeed.title || "",
        needId: findNeed.id || null,
        needStatus: findNeed.status,
        openLatestVersion: true,
        modal: 14.1,
      });
    },
    cancelNeed(index, parentId, comment) {
      const find = this.findItem(index, parentId);

      if (!find) return;
      if (!this.isString(comment)) {
        this.addWindow({
          title: "Anulare nevoie",
          subtitle: find.productName,
          rejectData: { needIndex: index, cancelNeed: true },
          labels: ["Anulat", "anulării", "Motivul anulării"],
          button: { value: "Anulare nevoie", type: 3 },
          modal: 11,
        });
      } else {
        const error = (msg) => {
          this.$toastr.e(msg || "Încercați mai târziu.");

          this.reloadPage();
          this.setLoad();
        };

        this.setSafeLoad();
        NEED_CANCEL(find.id, comment)
          .then((res) => {
            if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.$toastr.s("Nevoia a fost anulată.");

              this.reloadPage();
              this.setLoad();
            } else {
              error();
            }
          })
          .catch(error);
      }
    },
    changeStatusNeed(id, status, comment) {
      if (this.crudLoading || !status) return;
      const data = this.getNeedsTableData;
      if (!data || !data.length) return;
      const findNeed = data.find((el) => el.NR == id);
      if (!findNeed || !Number.isInteger(findNeed.id) || !findNeed.status)
        return;
      if ([4, 5].includes(status)) {
        NEEDS_AVIZO_MODIFY(findNeed.id, {
          uuid: this.getUuid(),
          action: status == 4 ? "rejected" : "approved",
          reason: comment ? comment : "No reason provided",
        })
          .then((res) => {
            if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.$toastr.s("Ați avizat nevoia cu succes.");
            } else {
              this.$toastr.e("Avizarea nevoii a eșuat.");
            }
            this.reloadPage();
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.reloadPage();
          });
        return;
      }
      const findAvizoMode = this.avizoModes.find((e) => e[findNeed.index]);
      if (findNeed.data.__Avizo) {
        this.addWindow({
          title: findNeed.productName || "Avizare nevoie",
          subtitle:
            { 1: "Avizare pozitivă", 2: "Avizare negativă" }[status] || "",
          rejectData: { needIndex: id, approving: status === 1 },
          avizoMode: findAvizoMode ? findAvizoMode[findNeed.index] : "",
          labels:
            status === 1
              ? ["Avizat pozitiv", "avizării pozitive", "Observații"]
              : [
                  "Avizat negativ",
                  "avizării negative",
                  "Motivul avizării negative",
                ],
          button:
            status === 1
              ? { value: "Avizare pozitiva", type: 2 }
              : { value: "Avizare negativa", type: 3 },
          modal: 11,
        });
        return;
      }
      this.crudLoading = true;
      if (status === 1) {
        this.crudLoading = false;
        findNeed.needIndex = id;
        this.addWindow({
          title: "Aprobă nevoie",
          subtitle: findNeed.productName || "",
          rejectData: findNeed,
          labels: ["Aprobat", "aprobării", "Observații"],
          button: { value: "Aproba", type: 2 },
          modal: 11,
        });
        return;
      }
      if (status === 2) {
        this.crudLoading = false;
        findNeed.needIndex = id;
        this.addWindow({
          title: "Respinge nevoie",
          subtitle: findNeed.productName || "",
          rejectData: { ...findNeed, rejecting: true },
          labels: ["Respins", "respingerii", "Motivul respingerii"],
          button: { value: "Respinge", type: 3 },
          modal: 11,
        });
        return;
      }
      const prepareData = new FormData();
      prepareData.append("status", status === 7 ? "approved" : "rejected");
      if (comment) {
        prepareData.append(
          status === 7 ? "observation" : "rejectReason",
          comment
        );
      }
      NEEDS_MODIFY_FORMDATA(findNeed.id, prepareData)
        .then((response) => {
          if (
            response &&
            response.message &&
            response.message.toLowerCase().includes("forbidden")
          ) {
            this.$toastr.e("Acces interzis.");
            this.crudLoading = false;
            return;
          }
          if (
            !(
              response &&
              response.meta &&
              String(response.meta.HttpStatusCode)[0] === "2"
            )
          ) {
            this.$toastr.e("Încercați mai târziu.");
            this.crudLoading = false;
            return;
          }
          this.$toastr.s(
            status === 7 ? "Necesitate aprobată." : "Necesitate respinsă."
          );
          this.crudLoading = false;
          this.reloadPage();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.crudLoading = false;
        });
    },
    closeCart() {
      this.cartVisible = false;
    },
    clearCartNeeds() {
      this.closeCart();
      this.cartData = [];
      this.showActiveRows = [];
      // this.reloadPage();

      localStorage.removeItem("needsCart");
    },
    createConexedNeed(needName) {
      const { cartData } = this;
      let isSocial = false;
      let isNonsocial = false;
      let isWrong = false;

      for (let need of cartData) {
        if (need.social === true) {
          isSocial = true;
        } else if (need.social === false) {
          isNonsocial = true;
        }

        if (isSocial && isNonsocial) {
          isWrong = true;
          break;
        }
      }

      if (isWrong) {
        this.$toastr.w(
          "Nevoia conexată nu poate fi compusă din nevoi Sociale și nevoi Non-sociale."
        );
        return;
      }

      if ((this.postCartType && !this.isString(needName)) || !needName.trim()) {
        this.$modal.show(
          DialogModal,
          {
            target: "AddConexedNeed",
            title: `Denumește nevoia ${
              this.getButtCartTypeInfo.val || "conexată"
            }`,
            inputs: [
              {
                label: `Denumire nevoie ${
                  this.getButtCartTypeInfo.val || "conexată"
                }`,
                id: "name",
                errReason: "Introduceți denumirea nevoii conexate.",
                checker: (x) => !!String(x).trim(),
              },
            ],
            closeBtnText: "Anulează",
            button: {
              type: 2,
              value: "Creează",
              handler: ({ name }) => this.createConexedNeed(name),
            },
          },
          {
            name: "AddConexedNeed",
            adaptive: true,
            width: "650",
            height: "400",
          }
        );

        return;
      }
      if (!this.postCartType && !needName) return;

      const prepareNeeds = this.cartData.map((e) => e.id);
      this.cartData = [];
      if (prepareNeeds.length) {
        const err = (msg) => {
          this.$toastr.e(msg || "Încercați mai târziu.");
          this.reloadPage();
        };
        NEEDS_CONEXE(prepareNeeds, needName)
          .then((res) => {
            if (res?.meta?.HttpStatusCode?.toString().charAt(0) === "2") {
              this.reloadPage();
              this.$toastr.s("Nevoia conexată a fost creată.");
            } else {
              err();
            }
            this.clearCartNeeds();
          })
          .catch(err);
      }
    },
    checkboxManager(_list) {
      this.showActiveRows = _list
      const needsList = this.getNeedsTableData;
      const preparation = [];
      let divisionCode;

      _list.forEach((i, index) => {
        const find = needsList[i.index];
   
        if (find) {
          if (index == 0) {
            divisionCode = find.data.product.cpvCategory.divisionCode
          } else if (find.data.product.cpvCategory.divisionCode != divisionCode) {
              this.delCheckbox(find.id);
              this.tableReload++;
              this.$toastr.w('Produsele nu fac parte din aceeași Diviziune CPV.')
              return
          }

          const id = find.id ? find.id : "-";
          const productName = find.productName ? find.productName : "-";
          const qty = find.qty ? find.qty : "-";
          const estimatedPrice = find.estimatedPrice
            ? find.estimatedPrice
            : "-";
          let social = find.data?.needIsSocial;
          if (typeof social != "boolean") social = null;

          preparation.push({
            id,
            productName,
            qty,
            estimatedPrice,
            social,
          });
        }
      });

      this.cartData = preparation;
      if (preparation.length) {
        localStorage.setItem("needsCart", JSON.stringify(preparation));
      } else {
        this.clearCartNeeds();
      }
    },
    delCheckbox(id) {
      if (!parseInt(id) && id !== 0) return;

      const getIndex = this.getNeedsTableData.find((e) => e.id === id).index;

      this.showActiveRows = this.showActiveRows.filter((e) => e.index !== getIndex);

      let find = localStorage.getItem("needsCart");

      if (find) find = JSON.parse(find);

      find = find.filter((e) => e.id !== id);

      this.cartData = find;

      if (find.length) {
        localStorage.setItem("needsCart", JSON.stringify(find));
      } else {
        this.clearCartNeeds();
      }
    },
    addNeed() {
      this.addWindow({
        title: "Adaugă propunere",
        modal: 1,
      });
    },
    openCronology(id, parentId) {
      const find = this.findItem(id, parentId);
      if (!find) return;
      const productName = find.data?.product ? find.data?.product?.name : false;
      this.addWindow({
        title: "Versiuni precedente",
        subtitle: productName ? `Nevoie: ${productName}` : "",
        needId: find.id || null,
        needStatus: find.needApprover
          ? "asteptare_editare_in_urma_anularii_de_catre_un_administrator"
          : find.data.status,
        canDeleteFiles:
          this.PERMISIONS.edit === true ||
          this.checker(
            this.PERMISIONS.edit,
            find.status,
            find.data?.createdByUser
          ),
        modal: 14.1,
      });
    },
    editNeed(index, parentId) {
      const find = this.findItem(index, parentId);
      if (!find) return;
      const x = find.data || {};
      
      if (x.needReferateObj) {
        this.$toastr.w({msg: 'Nevoia nu poate fi modificată. Există un referat generat!', timeout: 3000});
        return;
      }
      this.addWindow({
        title: "Modifică Nevoie",
        modal: 1.1,
        needId: find.id,
        showAvizo: ["editing", "rejected", "cancelled"].includes(find.status),
        afterActionStatus: ["cancelled", "rejected", "approved"].includes(find.status) ? "pending" : false,
        origin: {
          ...x,
          _name: this.readUserObject(x.createdByUser),
        },
        //needApprover: find.needApprover,
      });
    },
    delNeed(index, parentId) {
      const find = this.findItem(index, parentId);
      if (!find) return;
      this.addWindow({
        title: "Șterge propunere nevoie",
        modal: 1.2,
        data: {
          ...find.data,
          muName: find.data.product?.measurementUnit?.shortName,
          totalPrice: this.toPriceFormat(
            this._Multiply(find.data.qty, find.data.estimatedPrice)
          ),
        },
      });
    },
    readCheboxStorage() {
      let r = localStorage.getItem("needsCart");
      if (r) {
        r = JSON.parse(localStorage.getItem("needsCart"));
        const showActiveRows = [];
        this.cartData = r;
        const data = this.getNeedsTableData;
        r.forEach((e) => {
          const getIndex = data.find((el) => el.id === e.id)?.index;
          if (Number.isInteger(getIndex)) {
            showActiveRows.push(getIndex);
          }
        });
        this.showActiveRows = showActiveRows;
      } else {
        this.cartData = [];
        this.showActiveRows = [];
      }
    },
    syncCheckboxList() {
      window.addEventListener("storage", this.readCheboxStorage);
    },
    setPage(from, to) {
      if ((!parseInt(from) && from !== 0) || (!parseInt(to) && to !== 0)) {
        return;
      }
      this.currentPage.from = from;
      this.currentPage.to = to;

      this.managePages();
    },
    changePage(visibleRows) {
      this.currentPage.from = 0;
      this.currentPage.to = visibleRows;

      this.managePages(true);
    },
    async managePages(changePage) {
      if (this.dataLoading) {
        return;
      }
      this.dataLoading = true;
      let data = this.getNeedsTableData;

      const { from, to } = this.currentPage;
      const show = to - from;
      const currentPage = from / (to - from) + 1;
      const totalItems = this.totalItemsLength;


      if (to >= totalItems && data.length && changePage !== true) {
        return;
      }

      let uploadPages = [];
      let reloadRowsAfterChangeShow = false;

      if (currentPage > 0) {
        if (!data[from]) {
          uploadPages.push(currentPage);
        } else if (data[from] && !data[to - 1]) {
          this.data.body = [];
          data = [];
          reloadRowsAfterChangeShow = true;
          uploadPages.push(currentPage);
        }
      }
      if (Math.ceil(totalItems / show) - currentPage > 0) {
        if (!data[to]) {
          uploadPages.push(currentPage + 1);
        }
      }

      if (currentPage - 1 >= 1) {
        if (!data[from - show]) {
          uploadPages.push(currentPage - 1);
        }
      }

      if (
        this.tableReload === 1 &&
        uploadPages.length < 2 &&
        uploadPages.indexOf(currentPage + 1) == -1
      ) {
        uploadPages.push(currentPage + 1);
      }


      if (this.avizoMode) {
        uploadPages = [1];
      }


      if (uploadPages.length) {
        let preparation = [];
        let prepare = [];
        let i = 0;
        for (const page of uploadPages) {
          const getNeedsPage = await this.getNeedsData(show, page);
          preparation.push(getNeedsPage);
          i++;
        }
        const lastRowIndex = data[data.length - 1]
          ? data[data.length - 1].index + 1
          : 0;

        preparation = preparation.flat(1);
        this.data.subTableData = {};
        preparation.forEach((e, i) => {
          prepare.push(e.row);
          e.row.splice(this.PERMISIONS.conexe ? 1 : 0, 0, {
            val: lastRowIndex + (i + 1),
            border: "right",
          });
        });
        data.push(preparation);
        data = data.flat(1);
        if (this.avizoMode)
          data.sort(
            (a, b) =>
              new Date(b.data.createdAt).getTime() -
              new Date(a.data.createdAt).getTime()
          );
        else data.sort((a, b) => b.id - a.id);
        data.forEach((e, i) => {
          e.NR = i;
          e.index = i;
        });

        this.saveData(data);
        this.data.body =
          this.data.body.concat(prepare).length === 0
            ? [false]
            : this.data.body.concat(prepare);
        if (reloadRowsAfterChangeShow || this.tableReload == 1)
          this.tableReload++;
      } else if (!this.tableFilter.query) {
        let prepare = [];
        data.forEach((e) => {
          prepare.push(e.row);
        });
       
      }

      this.dataLoading = false;
      

      let checkboxList = [];

      for (let e of this.cartData) {
        const index = data.findIndex((row) => row.id === e?.id);
        if (~index && data[index]?.status == "approved") {
          checkboxList.push(index);
        } else {
          checkboxList = [];
          this.clearCartNeeds();
          break;
        }
      }
      this.showActiveRows = checkboxList;
    },
    prepareData(response = {}, o = {}) {
      if (this.avizoMode) {
        this.avizoModes = this.avizoModes.concat(
          response.avizo?.map((e, i) => ({
            [i]: this.isString(e.avizoMode, true)?.toLowerCase() || false,
          })) || []
        );
        response = [
          ...(response.approve || []),
          ...(response.avizoFacultativ?.length
            ? (response.avizo || []).concat(response.avizoFacultativ)
            : response.avizo || []
          )?.map((e) => ({ ...e.need, __Avizo: true })),
        ].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      } else {
        this.avizoModes = [];
      }
      const needTypesIcons = {
        open: { icon: "fas fa-unlock", tooltip: "Deblocat" },
        locked: { icon: "fas fa-lock", tooltip: "Blocat" },
        complex: { icon: ["complex.svg"], tooltip: "Complex" },
        similar: { icon: "fas fa-check-double", tooltip: "Similar" },
      };
      const conexedNeedTypes = ["similar", "complex"];
      const applicant = this.checkTableHeader("applicant");
      const depName = this.checkTableHeader("depName");
      const instName = this.checkTableHeader("instName");

      const editGranted = ["sef_institutie", "supervisor", "functionar"];
      const prepare = [];
      const roleName = this.getUserRole();
      let Urole = window.$getRoleToSend();
      Urole = Urole ? Urole.id : null;
      const getReferenceStatusIcon = (s) =>
        ({
          draft: {
            border: "left",
            icon: "far fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2.2rem;",
            tooltip: "Draft",
          },
          signature_waiting: {
            border: "left",
            icon: "fas fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2.2rem;",
            tooltip: "Așteptare semnare",
          },
          rejected: {
            border: "left",
            icon: "fas fa-times-circle",
            css: "padding: 0.5rem;color: #ec4a4a;font-size: 2.2rem;",
            tooltip: "Respins",
          },
          approved: {
            border: "left",
            icon: "fas fa-clipboard-check",
            css: "padding: 0.5rem;color: #569a36;font-size: 2.2rem;",
            tooltip: "Aprobat",
          },
          cancelled: {
            border: 'left',
            icon: 'fas fa-exclamation-circle',
            css: 'padding: 0.5rem;color: #ce302d;font-size: 2.2rem;',
            tooltip: 'Anulat',

          },
          completion: {
            border: 'left',
            icon: 'fas fa-pencil',
            css: 'padding: 0.5rem;color: #F2994A;font-size: 2rem;',
            tooltip: 'Trimis spre completare',
          },
          completed: {
            border: "left",
            icon: "fas fa-pencil",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Completat",
          },
          in_progress_signature: {
            border: "left",
            icon: "fas fa-pen-nib",
            css: "padding: 0.5rem;color: #F2994A; font-size: 2rem;",
            tooltip: "În curs de aprobare",
          },
        }[s] || s);
      let i = 1;
      for (const row of response) {

        const roleOwner = this.getUserRole(true) === row.createdByUserRole;

        const canConexe = row.needType === "open" && !row.needReferateObj && row.product.cpvCategory.cpvType == 1;

        const isConexed = ["complex", "similar"].includes(row.needType);

        const needApprover =
          row.approvers?.length === 0 && row.needType == "open";

        let rowCpvCode = "-";
        let rowProductName = "-";
        let rowMeasurementUnit = "-";
        let qty = "-";
        let estimatedPrice = "-";
        let institutionName = "-";
        let departmentName = "-";
        let createdBy = "-";

        if (row.product) {
          const pr = row.product;
          if (pr.name) {
            rowProductName = pr.name;
          }
          if (pr.cpvCategory && pr.cpvCategory.cpvCode) {
            if (pr.cpvCategory.name) {
              rowCpvCode = {
                val: pr.cpvCategory.cpvCode,
                tooltip: pr.cpvCategory.name,
              };
            } else {
              rowCpvCode = pr.cpvCategory.cpvCode;
            }
          }
          if (pr.measurementUnit && pr.measurementUnit.shortName) {
            if (pr.measurementUnit.description) {
              rowMeasurementUnit = {
                val: pr.measurementUnit.shortName,
                tooltip: pr.measurementUnit.description,
              };
            } else {
              rowMeasurementUnit = pr.measurementUnit.shortName;
            }
          }
          if (row.qty) {
            qty = row.qty;
          }
          if (row.estimatedPrice) {
            estimatedPrice = this.toPriceFormat(row.estimatedPrice);
          }
        }
        if (row.institution && row.institution.name) {
          institutionName = row.institution.name;
        }
        if (row.department && row.department.name) {
          departmentName = row.department.name;
        }
        if (row.createdByUser) {
          createdBy = `${row.createdByUser.last_name || ""} ${
            row.createdByUser.first_name || ""
          } ${row.id ? ` / ${row.createdByUser.id}` : ""}`;
        }

        if (isConexed) {
          rowMeasurementUnit = "";
          estimatedPrice = "";
        }

        let dominantNeed = row.dominantNeed;
      
        const preparation = [
          isConexed && row.needName != "none" && row.needName
            ? row.needName
            : rowProductName,
          isConexed ? dominantNeed?.product?.cpvCategory?.category || '-' : row.product?.cpvCategory?.category || '-',
          { val: window.$toDateAndTime(row.createdAt).split(" ").join("<br>") },
          !isConexed ? rowCpvCode : dominantNeed?.product?.cpvCategory?.cpvCode || '-' ,
          rowMeasurementUnit,
          isConexed ? "" : qty,
          isConexed ? "" : this.prepareLocaleNumber(row.estimatedPriceWoTva),
          isConexed ? "" : `${(+row.cotaTva)?.toFixed(2) || '0.00'} %`,
          isConexed ? "" : estimatedPrice,
          "<<PRICE>>",
          {
            true: "Social",
            false: "Non-social",
          }[row.needIsSocial] || "-",
          row.needReferateObj ? getReferenceStatusIcon(row.needReferateObj.needReferateStatus) : row.needReferenceStatus ? getReferenceStatusIcon(row.needReferenceStatus) : {
            border: "left",
            tooltip: "Referat inexistent",
            css: "font-size:2.2rem;color:#39f;",
            icon: "far fa-file",
          },
          needTypesIcons[row.needType]
            ? Object.assign({ border: "left" }, needTypesIcons[row.needType])
            : "",
          row.status || "",
        ];

        if (applicant) {
          preparation.unshift(createdBy ? { val: createdBy } : "");
        }
        if (depName) {
          preparation.unshift(departmentName);
        }
        if (instName) {
          preparation.unshift(institutionName);
        }

        const priceIndex = preparation.indexOf("<<PRICE>>");
        const statusIndex = preparation.indexOf(row.status);

        if (priceIndex > -1) {
          preparation[priceIndex] = this.toPriceFormat(row?.sumNeed);
        }

        const status = String(row.status).toLowerCase().trim();
        const x = this.PERMISIONS;
        const prepareBtn = [];

        const uuid = row.createdByUser?.uuid;
        let approver =
          Array.isArray(row.approvers) && row.approvers[0]
            ? row.approvers[0]
            : false;
        approver = this.JSONvalide(approver)
          ? JSON.parse(approver)[0]?.split("*")
          : this.isString(approver, true)?.split("*") || [];

        let allowEdit = false;

        if (
          roleOwner &&
          this.getUuid() === uuid &&
          !["approved", "cancelled", "generated"].includes(row.status)
        ) {
          prepareBtn.push([
            29,
            "Anulează",
            ["fas fa-window-close", "font-size:2.2rem;color:#ec4a4a;"],
          ]);
        }

        if (needApprover) {
          allowEdit = true;
        } else {
          if (row.__Avizo) {
            if (["avizo", "approvalready"].includes(status)) {
              prepareBtn.push([
                8,
                "Avizează pozitiv",
                ["fas fa-clipboard-check", "font-size:2.2rem;color:green;"],
              ]);
              prepareBtn.push([
                9,
                "Avizează negativ",
                ["fas fa-clipboard-check", "font-size:2.2rem;color:#ea2f2f;"],
              ]);
            }
          } else {
            if (this.avizoMode) {
              prepareBtn.push(8);
              prepareBtn.push(9);
            } else {
              if (
                ["avizo", "approvalReady"].includes(row.status) &&
                row.createdByUserRole === Urole
              ) {
                prepareBtn.push([
                  14,
                  "Oprire flux de avizare",
                  ["fas fa-times-circle", "font-size:2.2rem;color:red;"],
                ]);
              }
              if (roleOwner && editGranted.includes(roleName)) {
                if (["rejected", "editing", "cancelled"].includes(status)) {
                  allowEdit = true;
                }
                if (
                  !isConexed&&
                  !row.parentConnectedId &&
                  (x.delete === true ||
                  this.checker(x.delete, status, uuid))
                  && !row.needReferateObj
                ) {
                  prepareBtn.push(3);
                }
              }
            }
          }
        }

        if (x.edit === true || this.checker(x.edit, status, uuid)) {
          allowEdit = true;
        }   

        if (!editGranted.includes(roleName)) {
          allowEdit = false;
        }       

        if (
          !row.needReferateObj &&
          row.needType != "locked" &&
          (row.status == "approved" || row.status == "generated") &&
          !this.isSuperadmin && 
          row.createdByUserRoleName == this.getUserRole()
        ) {
          prepareBtn.push([
            20,
            "Generare RF",
            ["fas fa-file", "font-size:2.2rem;color:#2196f3;"],
          ]);
        }
        
        if (
          o.isFromConexed &&
          !this.avizoMode &&
          +row.parentConnectedId &&
          (x.unpairSimple === true ||
            this.checker(x.unpairSimple, status, uuid))
        ) {
          prepareBtn.push([9, "Anulează conexarea"]);
        }
        if (x.cronology === true || this.checker(x.cronology, status, uuid)) {
          prepareBtn.push(13);
        }
        if (allowEdit && !isConexed && row.needType != "locked" && roleOwner) {
          prepareBtn.unshift(2);
        }

        if (statusIndex > -1) {
          const statusesMap = {
            pending: { border: "left", tooltip: 'Așteptare aprobare', icon: "far fa-clock" },
            approvalready: { border: "left", tooltip: 'Așteptare aprobare <br/> în urma avizării', icon: "far fa-calendar-check" },
            approved: { border: "left", tooltip: 'Aprobat', icon: "fas fa-clipboard-check" },
            rejected: { border: "left", tooltip: 'Respins', icon: "fas fa-times-circle" },
            avizo: { border: "left", tooltip: 'În proces <br/>de avizare', icon: "fas fa-low-vision" },
            editing: {
              border: "left",
              tooltip: "Așteptare editare în <br/>urma avizării negative",
              css: "font-size:2.2rem;color:#39f;",
              icon: "fas fa-times-circle",
            },
            disabled: {
            border: 'left',
            tooltip: "Dezactivat",
            css: "font-size:2.2rem;color:red;",
            icon: "fas fa-exclamation-circle",
          },
          cancelled: { border: "left", tooltip: 'Anulat', icon: "fas fa-ban" },
          generated: { border: "left", tooltip: 'Generat', icon: "fas fa-sparkles", css: "font-size: 2.5rem; color: #f2da07", },
          };

    
          if (needApprover) {
            preparation[statusIndex] = {
              border: "left", tooltip: 'Așteptare',
              icon: "fas fa-exclamation-circle",
            };
          } else {
            preparation[statusIndex] =
              statusesMap[status] || row?.status || "-";
          }
          if (this.PERMISIONS.conexe)
         {   preparation.unshift(
              (status === "approved" || status === "generated") && canConexe && !o.noCheckbox
                ? { checkbox: true }
                : ""
            );}
        }
        if (conexedNeedTypes.includes(row.needType)) {
          if (
            x.deleteConexed === true ||
            this.checker(x.deleteConexed, status, uuid)
          ) {
            prepareBtn.push(3);
          }
          prepareBtn.push(1);
        }
        if (this.isEditable) {
          preparation.push([prepareBtn]);
        }
        if (o.minimize) {
          preparation.splice(this.PERMISIONS.conexe ? 1 : 0, 0, {
            val: i,
            border: "right",
          });
          prepare.push(preparation);
        } else {
          if (o.numerotate) {
            preparation.splice(this.PERMISIONS.conexe ? 1 : 0, 0, {
              val: i,
              border: "right",
            });
          }

          const id = row.id ? row.id : "-";

          const returnData = {
            row: preparation,
            id: id,
            productName: rowProductName,
            qty: qty,
            estimatedPrice: estimatedPrice,
            status: row.status,
            data: row,
            needApprover,
          };

          prepare.push(returnData);
        }
        i++;
        this.data.institution = row.institution
      }
      
      if (o.minimize) {
        prepare.forEach((e, i) => (e._ID = i));
      } else if (o.minimize === false || o.numerotate) {
        prepare.forEach((e, i) => {
          e.NR = i;
          e.index = i;
        });
      }
      if (o.minimize !== undefined) {
        this.dataLoading = false;
      }
      return o.minimize && !prepare.length ? [false] : prepare;
    },
    async getNeedsData(showPerPage, page) {
      let prepare = [];
      const data = this.getNeedsTableData;
      let responseNeedsData = await (this.avizoMode
        ? NEEDS_AVIZO
        : NEEDS_PAGINATED)(
        page,
        this.avizoMode ? 400 : showPerPage,
        false,
        this.tableFilter.baseQuery.concat(this.tableFilter.query) || false
      );
      if (Number.isInteger(responseNeedsData?.data?.recordsQuantity)) {
        this.totalItemsLength = responseNeedsData.data.recordsQuantity;
        this.data.options.totalItems = responseNeedsData.data.recordsQuantity;
      }
      if (
        this.avizoMode
          ? Number.isInteger(responseNeedsData?.data?.recordsQuantity)
          : responseNeedsData?.data?.result?.length
      ) {
        prepare = this.prepareData(responseNeedsData.data.result);
      }
      return prepare;
    },
    checkOwner(option, status, uuid) {
      if (!option) return false;
      if (option == uuid) {
        return true;
      }
      if (
        Array.isArray(option) &&
        option.length > 1 &&
        option[0].includes(status) &&
        option[1] == uuid
      ) {
        return true;
      }
      return false;
    },
    checkStatus(option, status) {
      if (Array.isArray(option) && option.includes(status)) {
        return true;
      }
      return false;
    },
    checker(option, status, uuid) {
      if (this.checkStatus(option, status)) {
        return true;
      }
      if (this.checkOwner(option, status, uuid)) {
        return true;
      }
      return false;
    },
    breadcrumbsInit() {
      const role = window.$getRoleAll();
      if (!role) {
        return;
      }

      const adminsMap = [
        "admin_institutie",
        "supervisor",
        "responsabil_achizitii",
        "supervizor_achizitii",
      ];
      let setBreadCrumb = "";

      switch (role.role) {
        case "functionar":
          setBreadCrumb = ` / ${this.getUserName()} ${this.changeRoleView(
            "functionar"
          )}`;
          break;
        case "sef_institutie":
          setBreadCrumb = ` / Instituție`;
          break;
        case "superadmin":
          setBreadCrumb = ` / Superadmin`;
          break;
      }
      if (!setBreadCrumb && adminsMap.indexOf(role.role) != -1) {
        setBreadCrumb = " / Departament";
      }
      this.breadcrumbs = setBreadCrumb;
    },
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.readCheboxStorage);
  },
  async created() {
    this.breadcrumbsInit();
    if (!this.PERMISIONS.list) {
      return;
    }
    this.saveData([]);
    this.initHeadTable();
    if (this.PERMISIONS.conexe) {
      this.data.head.unshift({
        spacer: true,
        minimize: 3,
        fixed: { left: true },
      });
    }
    const baseKeys = ["list", "create", "createProposal"];
    if (this.checkParamsInObj(this.PERMISIONS, baseKeys)) {
      this.isEditable = true;
      this.data.head.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: "0px",
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
      this.$watch("data.body", () => {
        if (this.data.body.length) {
          this.data.body.forEach((e) => {
            const find = e[e.length - 2];
            if (typeof find === "object") find.border = "left";
          });
        }
      });
    }
    await this.updateRoleStatus();
    await this.managePages(true);

    this.syncCheckboxList();

  },
};

</script>

<style scoped>
.col2_subHead {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 99;
}
</style>
<style src="@/assets/Needs.css"></style>